import React, { useState, useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import CALENDAR from "../images/Calendar.svg"
import LINKEDIN from "../images/linkedin.svg"

import KAYHAN_BOLUKBAS from "../images/kayhanbolukbas.jpg"
import ALP_KARAOSMANOGLU from "../images/alp_karaosmanoglu.jpg"
import KAAN_KUN from "../images/kaan_kun.jpg"
import MURAT_EMRE_AYGUN from "../images/murat_emre_aygun.jpg"
import EMRE_ONER from "../images/emre_oner.jpg"
import BUGRA_ONER from "../images/bugra_oner.jpg"
import ARDA_ALTUNYAY from "../images/arda_altunyay.jpg"
import EREN_ALPASLAN from "../images/eren.jpg"
import AHMET_ENES_ADLIG from "../images/ahmet_enes_adlig.jpg"
import ESRA from "../images/esra.jpeg"
import YUNUS_BAGRIYANIK from "../images/yunusbagriyanik.jpeg"
import CENK_OZTURK from "../images/cenkozturk.jpeg"
import ORCUN from "../images/orcun.jpeg"
import DOGANCAN from "../images/dogancan.jpeg"
import ENES from "../images/enes.jpeg"
import FATIH_SAHIN from "../images/fatihsahin.jpeg"
import BURAK from "../images/burakeraslan.jpeg"
import HASAN_ARINC from "../images/hasanarinc.jpeg"
import FAVICON from "../images/favicon.png"
import ALI_SAMIL_KUCUK from "../images/ALI_SAMIL_KUCUK.jpg"
import AHMET_FARUK_ACAR from "../images/AHMET_FARUK_ACAR.jpg"
import BARIS_KESER from "../images/BARIS_KESER.jpg"
import CANER_KAYA from "../images/CANER_KAYA.jpg"
import DOGANCAN_MAVIDENIZ from "../images/DOGANCAN_MAVIDENIZ.jpg"
import GOKSU_BAYRAM from "../images/GOKSU_BAYRAM.jpg"
import HARUN_TURKOGLU from "../images/HARUN_TURKOGLU.jpg"
import HASAN_ALI_SISECI from "../images/HASAN_ALI_SISECI.jpg"
import MAHSUN_ABUZEYITOGLU from "../images/MAHSUN_ABUZEYITOGLU.jpg"
import MUSTAFA_ALTIPARMAK from "../images/MUSTAFA_ALTIPARMAK.jpg"
import SIMGE_CAKIR from "../images/SIMGE_CAKIR.jpg"
import IOS from "../images/ios.png"
import ANDROID from "../images/android.png"
import INSTAGRAM from "../images/instagram.svg"
import INCEISLER from "../images/inceisler.png"
import MYHELIUM from "../images/myhelium.png"


const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImg: file(relativePath: { eq: "hero-img.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hi: file(relativePath: { eq: "hid.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const about = useRef(null)
  // const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    if (typeof window === "undefined") return
    setHeight(window.innerHeight)

    const handleResize = () => {
      // setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  })

  return (
    <Layout>
      <SEO title="Apptern | Daha Yeni Başlıyoruz!" />
      <div className="home">
        <section className="hero">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-3 col-6 mt-3 pt-3 text-right">
                  <img src={IOS} width={80}  />
              </div>
              <div className="col-md-3 col-6 pt-3 mt-3 d-sm-block d-md-block d-lg-none d-xl-none">
                <div className="hero-img">
                <img src={ANDROID} width={125} />
                </div>
              </div>
              <div className="col-md-5 col-sm-12 text-center">
                <div className="hero-msg">
                  <h1 className="font-style-bold">
                    Apptern ile Daha Yeni Başlıyoruz!
                  </h1>
                  <p>
                  4. Dönem Apptern Genç Yetenek Programı değerlendirme sürecimiz tamamlandı. Başvuran tüm adaylara teşekkür ederiz. Önümüzdeki dönemlerde görüşmek üzere. 
                  </p>
                  <div className="date-ctn">
                    {/* <img
                      width="32"
                      height="32"
                      src={CALENDAR}
                      alt="calendar_icon"
                    /> */}
                    {/* <div className="font-style-semi-bold date">
                      Başvuru Tarihleri: 28 Mart-22 Nisan
                    </div> */}
                  </div>
                  <div className="button-ctn">
                    {/* <div className="cta">
                      <a
                        href="https://docs.google.com/forms/d/1D4KsVi6kx1nHUz6PdTbvaUc7_nJntFzxVuu_hEwZRfs/viewform?edit_requested=true"
                        target="_blank"
                        rel="noreferrer"
                        className="button font-style-semi-bold "
                      >
                        Hemen Başvur
                      </a>
                    </div> */}
                    {/* <div className="button-holder">
                      <div className="cta linkedin">
                        <a
                          href="https://www.linkedin.com/jobs/view/2731049680"
                          target="_blank"
                          rel="noreferrer"
                          className="button font-style-semi-bold "
                        >
                          <img src={LINKEDIN} alt="linkedin" /> Mobile Apptern'e
                          Başvur
                        </a>
                      </div>
                      <div className="cta linkedin">
                        <a
                          href="https://www.linkedin.com/jobs/view/2731048692"
                          target="_blank"
                          rel="noreferrer"
                          className="button font-style-semi-bold "
                        >
                          <img src={LINKEDIN} alt="linkedin" /> Java Apptern'e
                          Başvur
                        </a>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-12 pt-3 mt-3 d-none d-sm-none d-md-none d-lg-block d-xl-block">
                <div className="hero-img">
                <img src={ANDROID} width={125} />
                </div>
              </div>
              <div className="col-lg-4 col-md-12 text-center">
              <p>Son Başvuru Tarihi: 1 Mayıs</p>
              <div className="button-ctn">
                     <div className="cta">
                      <a
                        href="#"
                        target="_blank"
                        rel="noreferrer"
                        className="button font-style-semi-bold isDisabled" 
                      >
                        Hemen Başvur
                      </a>
                    </div>
                    </div>
              </div>
            </div>
          </div>
        </section>
        {/* <div
          className={`scroll-indicator ${height > 700 ? "static" : "absolute"}`}
          onClick={() =>
            about.current.scrollIntoView({ behavior: "smooth", block: "start" })
          }
        >
          <div>KEŞFET</div>
          <img src={ARROW} alt="arrow" width="16" height="19" />
        </div> */}
        <section id="hakkinda" className="about" ref={about}>
          <div className="container">
            <div className="row  justify-content-center">
              <div className="col-lg-8 col-md-6">
                {/* <h3>Bilgilendirme</h3>
                <div className="about-ctn">
                  <p>
                    Apptern Genç Yetenek Programımıza başvurun için teşekkür
                    ederiz. Süreçte başvuruların hepsi büyük bir titizlikle
                    değerlendirildi. Değerlendirme sonucu seçilen
                    arkadaşlarımızla Ödev ve Assessment Center'a seçim sürecini
                    tamamladık. Son aşama olan Assessment Center uygulamasına
                    geçen arkadaşlarımız ile sürecimiz devam ediyor. Sürece
                    devam edemediğimiz adaylarımız; sizlere bu süreçte yoğun
                    ilginiz için teşekkür ederiz.
                  </p>
                  <p>
                    İlerleyen zamanlarda Genç Yetenek Programlarımızı yapmaya
                    devam edeceğiz. Program duyurularımızı Linkedin ve sosyal
                    medya sayfamızdan takip edebilirsin.
                  </p>
                </div> */}
                <h3>Appcent Hakkında</h3>
                <div className="about-ctn">
                  <p>
                  2013’te Ozan Uysal’ın liderliğinde fiziksel mağaza deneyimini mobile taşıma amacıyla kurulan Appcent, o günden bugüne sektörde edindiği tecrübeler ile bugün aralarında Fortune 500 şirketlerini de bulunduran ve yurtdışına ihracat yapan bir lider teknoloji firmasına dönüştü. 10 yıldan kısa bir sürede 100’den fazla ekip arkadaşı ve 40’tan fazla müşteriye ulaşmanın mutluluğunu yaşayan Appcent, yazılım geliştirme alanındaki uzmanlığının yanı sıra Appcent Design olarak firmalara tasarım desteği sunarken, Appcent Akademi olarak da kurumsal ve bireysel eğitimler vermektedir. Gelişen backend ekibiyle de firmaların dijital dünyadaki en iyi teknoloji partneri haline gelmenin gururunu yaşıyor. </p>
                </div>
                <h3>Apptern Hakkında</h3>
                <div className="about-ctn">
                  <p>
                  Türkiyenin en büyük teknoloji şirketlerinden biri olarak Genç Yetenek Programı’mız Apptern’ün dördüncü dönemini heyecanla başlatıyoruz! 🚀 2 ay sürecek bu macera dolu yolculukta sürekli gelişip yenilenen lider bir teknoloji firmasında çalışma fırsatına sahip olacaksın! Mobil dükkanlardaki ilk 10’a giren projeleri gerçekleştiren eğitmenlerimiz tarafından mobil yazılım ve kişisel gelişim eğitimleri ile zenginleşecek, sosyalleşecek, mobil dükkanlarda yayınlanacak projelerde çalışarak sektörü ve sektör liderlerini tanıma fırsatı yakalayacak ve tam anlamıyla 
                   <a
                      href="https://www.instagram.com/explore/tags/appcentpeople/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <b> #appcentpeople</b></a>'ın bir parçası olacaksın 🧡 Seçtiğin alanda uzmanlaşmanın sağlam adımlarını atacağın süreçte ilgi alanına göre ister iOS ister Android programına başvuru yapmakta özgürsün. Mobil uygulama geliştirmek benim işim diyorsan ve “Daha yeni başlıyoruz!” mottosunu içselleştirdiysen seni Apptern olmaya davet ediyoruz.
                    
                    
                  </p>
                  
                </div>
              </div>
              <div className="col-lg-10 col-md-10">
              <h3>Önceki Dönem Mezun Projeleri</h3>

                <div className="row justify-content-center">
                <div className="col-lg-4 col-md-4">
                  <div className="projects mb-3">
                  <img src={INCEISLER} width={100} className="inceisler-logo"  />
                    <div className="title">İnce İşler</div>
                    <div className="subtile">2. Dönem</div>
                    <div className="link"><a href="https://onelink.to/tr8nys" >iOS & Android</a></div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                <div className="projects mb-3">
                <img src={MYHELIUM} width={100} className="inceisler-logo"  />
                  <div className="title">My Helium Setup</div>
                  <div className="subtile">3. Dönem</div>
                  <div className="link"><a href="https://www.onelink.to/my-onelinks" >iOS & Android</a></div>
                </div>
                </div>
                </div>
              </div>
            </div>

          </div>
        </section>
        <section id="program" className="program">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-10">
                <h3>Program</h3>
                <div className="timeline">
                  <div className="log right">
                    <div className="log-point"></div>
                    <div className="log-content-wrp">
                      <div className="date-wrp">
                        <div className="date font-style-semi-bold">
                          <span>3 Nisan</span> - <span>1 Mayıs</span>
                        </div>
                      </div>
                      <div className="log-content">
                        <h4>
                          Online başvuru
                          {/* <span>📮</span> */}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="log">
                    <div className="log-point"></div>
                    <div className="log-content-wrp">
                      <div className="date-wrp">
                        <div className="date font-style-semi-bold">
                          <span>2 Mayıs</span> - <span>16 Haziran</span>
                        </div>
                      </div>
                      <div className="log-content">
                        <h4>
                          Başvuruların Değerlendirilmesi ve Sonuçların
                          Açıklanması
                          {/* <span>📝</span> */}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="log right">
                    <div className="log-point"></div>
                    <div className="log-content-wrp">
                      <div className="date-wrp">
                        <div className="date font-style-semi-bold">
                          <span>3 Temmuz</span>
                        </div>
                      </div>
                      <div className="log-content">
                        <h4>
                          Apptern'ler Appcent'te!
                          {/* <span>💃</span> */}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="log">
                    <div className="log-point"></div>
                    <div className="log-content-wrp">
                      <div className="date-wrp">
                        <div className="date font-style-semi-bold">
                          <span>3 - 14 Temmuz</span>
                        </div>
                      </div>
                      <div className="log-content">
                        <h4>
                          Oryantasyon ve Appcent Akademi ile Teknik Eğitim
                          Maratonu
                          {/* <span>🧑‍🏫</span> */}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="log right">
                    <div className="log-point"></div>
                    <div className="log-content-wrp">
                      <div className="date-wrp">
                        <div className="date font-style-semi-bold">
                          {/* <span>20 Haziran</span> */}
                        </div>
                      </div>
                      <div className="log-content">
                        <h4>
                          Eğlence ve Happy Hour'lar
                          {/* <span>🎊</span> */}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="log">
                    <div className="log-point"></div>
                    <div className="log-content-wrp">
                      <div className="date-wrp">
                        <div className="date font-style-semi-bold">
                          <span>17 Temmuz</span>
                        </div>
                      </div>
                      <div className="log-content">
                        <h4>
                          Projeler Başlasın
                          {/* <span>🏁</span> */}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="log right">
                    <div className="log-point"></div>
                    <div className="log-content-wrp">
                      <div className="date-wrp">
                        <div className="date font-style-semi-bold">
                          <span className="log-span">
                            17 Temmuz - 27 Ağustos
                          </span>
                        </div>
                      </div>
                      <div className="log-content">
                        <h4>
                          Mentorluk / Teknik ve Kişisel Gelişim Eğitimleri{" "}
                          {/* <span>🧑‍💻</span> */}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="log">
                    <div className="log-point"></div>
                    <div className="log-content-wrp">
                      <div className="date-wrp">
                        <div className="date font-style-semi-bold">
                          <span>28 Ağustos</span>
                        </div>
                      </div>
                      <div className="log-content">
                        <h4>
                          Apptern Mezuniyet ve Happy Hour{" "}
                          {/* <span>🎓</span>  */}
                          {/* <span>🥳</span> */}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="mentorlar" className="mentors">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3>Mentorlar</h3>
              </div>
            </div>
            <div className="row justify-content-center">
            <div className="col-12 col-lg-4">
                <a
                  href="https://www.linkedin.com/in/alisamilkucuk/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="judge">
                    <div className="name-card no-bot">
                      <div className="img-ctn">
                        <img src={ALI_SAMIL_KUCUK} alt="Ali Şamil Küçük (2. Dönem Apptern Mezunu) " />
                      </div>
                      <div className="name-ctn">
                        <div className="name font-style-bold">Ali Şamil Küçük </div>
                        <div className="title font-style-semi-bold">
                        2. Dönem Apptern Mezunu
                        </div>
                        <div className="title font-style-semi-bold">
                          Android Geliştirici
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-12 col-lg-4">
                <a
                  href="https://www.linkedin.com/in/ahmetfarukacar/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="judge">
                    <div className="name-card no-bot">
                      <div className="img-ctn">
                        <img src={AHMET_FARUK_ACAR} alt="Ahmet Faruk Acar (1. Dönem Apptern Mezunu)" />
                      </div>
                      <div className="name-ctn">
                        <div className="name font-style-bold">Ahmet Faruk Acar</div>
                        <div className="title font-style-semi-bold">
                        1. Dönem Apptern Mezunu
                        </div>
                        <div className="title font-style-semi-bold">
                          iOS Geliştirici
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-12 col-lg-4">
                <a
                  href="https://www.linkedin.com/in/barisskeser/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="judge">
                    <div className="name-card no-bot">
                      <div className="img-ctn">
                        <img src={BARIS_KESER} alt="Barış Keser" />
                      </div>
                      <div className="name-ctn">
                        <div className="name font-style-bold">Barış Keser</div>
                        <div className="title font-style-semi-bold">
                          Android Geliştirici
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-12 col-lg-4">
                <a
                  href="https://www.linkedin.com/in/caner-kaya-5121a01b9/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="judge">
                    <div className="name-card no-bot">
                      <div className="img-ctn">
                        <img src={CANER_KAYA} alt="Caner Kaya (2. Dönem Apptern Mezunu) " />
                      </div>
                      <div className="name-ctn">
                        <div className="name font-style-bold">Caner Kaya</div>
                        <div className="title font-style-semi-bold">
                        2. Dönem Apptern Mezunu
                        </div>
                        <div className="title font-style-semi-bold">
                          iOS Geliştirici
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-12 col-lg-4">
                <a
                  href="https://www.linkedin.com/in/mavideniz/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="judge">
                    <div className="name-card no-bot">
                      <div className="img-ctn">
                        <img src={DOGANCAN_MAVIDENIZ} alt="Doğancan Mavideniz" />
                      </div>
                      <div className="name-ctn">
                        <div className="name font-style-bold">Doğancan Mavideniz</div>
                        <div className="title font-style-semi-bold">
                        2. Dönem Apptern Mezunu
                        </div>
                        <div className="title font-style-semi-bold">
                          iOS Geliştirici
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-12 col-lg-4">
                <a
                  href="https://www.linkedin.com/in/eren-alpaslan-03a271180/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="judge">
                    <div className="name-card no-bot">
                      <div className="img-ctn">
                        <img src={EREN_ALPASLAN} alt="Eren Alpaslan" />
                      </div>
                      <div className="name-ctn">
                        <div className="name font-style-bold">Eren Alpaslan</div>
                        <div className="title font-style-semi-bold">
                          Android Geliştirici
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-12 col-lg-4">
                <a
                  href="https://www.linkedin.com/in/goksubayram/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="judge">
                    <div className="name-card no-bot">
                      <div className="img-ctn">
                        <img src={GOKSU_BAYRAM} alt="Göksu Bayram" />
                      </div>
                      <div className="name-ctn">
                        <div className="name font-style-bold">Göksu Bayram</div>
                        <div className="title font-style-semi-bold">
                          iOS Geliştirici
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-12 col-lg-4">
                <a
                  href="https://www.linkedin.com/in/devharunturkoglu/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="judge">
                    <div className="name-card no-bot">
                      <div className="img-ctn">
                        <img src={HARUN_TURKOGLU} alt="Harun Türkoğlu" />
                      </div>
                      <div className="name-ctn">
                        <div className="name font-style-bold">Harun Türkoğlu</div>
                        <div className="title font-style-semi-bold">
                          Android Geliştirici
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-12 col-lg-4">
                <a
                  href="https://www.linkedin.com/in/hasanalisiseci/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="judge">
                    <div className="name-card no-bot">
                      <div className="img-ctn">
                        <img src={HASAN_ALI_SISECI} alt="Hasan Ali Şişeci (2. Dönem Apptern Mezunu)" />
                      </div>
                      <div className="name-ctn">
                        <div className="name font-style-bold">Hasan Ali Şişeci</div>
                        <div className="title font-style-semi-bold">
                        2. Dönem Apptern Mezunu
                        </div>
                        <div className="title font-style-semi-bold">
                          iOS Geliştirici
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-12 col-lg-4">
                <a
                  href="https://www.linkedin.com/in/hasan-ar%C4%B1n%C3%A7-599ab7186/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="judge">
                    <div className="name-card no-bot">
                      <div className="img-ctn">
                        <img src={HASAN_ARINC} alt="Hasan Arınç" />
                      </div>
                      <div className="name-ctn">
                        <div className="name font-style-bold">Hasan Arınç</div>
                        <div className="title font-style-semi-bold">
                          Android Geliştirici
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-12 col-lg-4">
                <a
                  href="https://www.linkedin.com/in/mahsun-abuzeyitoglu-8bb2b0a7/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="judge">
                    <div className="name-card no-bot">
                      <div className="img-ctn">
                        <img src={MAHSUN_ABUZEYITOGLU} alt="Mahsun Abuzeyitoğlu" />
                      </div>
                      <div className="name-ctn">
                        <div className="name font-style-bold">Mahsun Abuzeyitoğlu</div>
                        <div className="title font-style-semi-bold">
                          iOS Geliştirici
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-12 col-lg-4">
                <a
                  href="https://www.linkedin.com/in/mustafa-alt%C4%B1parmak-4b8423231/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="judge">
                    <div className="name-card no-bot">
                      <div className="img-ctn">
                        <img src={MUSTAFA_ALTIPARMAK} alt="Mustafa Altıparmak" />
                      </div>
                      <div className="name-ctn">
                        <div className="name font-style-bold">Mustafa Altıparmak</div>
                        <div className="title font-style-semi-bold">
                          iOS Geliştirici
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-12 col-lg-4">
                <a
                  href="https://www.linkedin.com/in/simge-%C3%A7ak%C4%B1r/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="judge">
                    <div className="name-card no-bot">
                      <div className="img-ctn">
                        <img src={SIMGE_CAKIR} alt="Simge Çakır" />
                      </div>
                      <div className="name-ctn">
                        <div className="name font-style-bold">Simge Çakır</div>
                        <div className="title font-style-semi-bold">
                          iOS Geliştirici
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>




            </div>
          </div>
        </section>
        <section id="sss" className="faq">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-8">
                <h3>Sıkça Sorulan Sorular</h3>
                <div className="collapse">
                  <Accordion>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Apptern olmak için neler gerekir?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          Bilgisayar Mühendisliği, Yazılım Mühendisliği,
                          Elektrik-Elektronik Mühendisliği, Matematik
                          Mühendisliği, İstatistik, Matematik, Yönetim Bilişim
                          Sistemleri Endüstri Mühendisliği veya ilgili
                          bölümlerde 4. Sınıf öğrencisi olmak.
                        </p>
                        <p>Bu yaz döneminde ya da Ocak 2024 tarihinde mezun olmak.</p>
                        <p>3 Temmuz-28 Ağustos tarihlerinde programımıza tam olarak zamanını ayırabilmek.</p>
                        <p>
                          İlgi alanına göre backend ile ilgileniyorsan Java,
                          mobil ile ilgileniyorsan iOS veya Android ödev yapmak.
                        </p>
                        <p>
                        İngilizce okuma, anlama, yazma yetkinliğine sahip olmak.
                        </p>
                        <p>Teknolojiyi çok sevmek. 📱</p>

                        <p>
                        <a href="https://www.instagram.com/tv/CcFX3SnjtRn/?igshid=YmMyMTA2M2Y=">
                        <img src={INSTAGRAM} alt="instagram" width={15} className="mr-2" />
                          <span>Merak ettiklerinizin daha fazlasına geçmiş dönem videomuzdan ulaşabilirsiniz.</span>
                        </a>
                        </p>


                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Apptern’e katılmak için hangi aşamalardan geçersin?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <ul>
                          <li>Online ilanımıza başvuru</li>
                          <li>Telefon mülakatı</li>
                          <li>Ödev çalışması</li>
                          <li>Teknik mülakat</li>
                          <li>Grup case çalışması</li>
                        </ul>
                        <p>Appcent ailesine hoş geldin 😊</p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Apptern olduğunda seni neler bekler?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <ul>
                          <li>Bire bir mentorluk.</li>
                          <li>Mobil yazılım eğitimi.</li>
                          <li>Teknik gelişim eğitimleri.</li>
                          <li>
                            Şirketteki tüm birimleri tanıma, oryantasyon
                            fırsatı.
                          </li>
                          <li>
                            CEO ve alanında yetkin yöneticilerden tavsiyeler.
                          </li>
                          <li>Eğlenceli oyunlar ve happy hour etkinlikleri.</li>
                          <li>
                            Kariyerin boyunca sana fayda sağlayacak iletişim,
                            kariyer mentorluğu, zaman yönetimi, stres yönetimi
                            eğitimleri.
                          </li>
                          <li>
                            Senin için kurgulanmış profesyonel bir proje
                            geliştirme fırsatı.
                          </li>
                          <li>
                          Alacağın tüm eğitimler alanında uzman kişiler ve kurumlar tarafından sağlanacaktır.
                          </li>
                          <li>
                          ​​Türkiye’nin her yerinden katılabileceğin tamamen online ilerleyecek bir uzaktan çalışma programı
                          </li>
                        </ul>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Apptern programı ne kadar sürer?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                        Kendini geliştirebileceğin içeriklere sahip 2 ay sürecek bir gelişim maratonu!
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Farklı illerden katılabilir miyim?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                        Türkiye’nin herhangi bir ilinden katılabilirsin. Tamamen online olarak kendini geliştirebileceğin bir ortam seni bekliyor.
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Apptern programını başarılı tamamladığında Appcent’te
                          seni neler bekler?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          <ul>
                            <li>Tam zamanlı çalışma imkanı.</li>
                            <li>Uzaktan çalışma olanağı.</li>
                            <li>Çeşitli lokal ve global projeleri görme fırsatı.</li>
                          </ul>
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          Bizi nerelerden takip edebilirsin?
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p>
                          <ul>
                            <li>
                              <a
                                href="https://www.linkedin.com/company/appcent/"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Linkedin
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://twitter.com/appcenttech"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Twitter
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://kommunity.com/@appcentakademi"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Kommunity
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://medium.com/appcent"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Medium
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://www.instagram.com/appcenttech/"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Instagram
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://appcent.mobi"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Web Sitesi
                              </a>
                            </li>
                          </ul>
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="basvuru" className="apply">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3>Başvuru</h3>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-md-6">
                <div className="apply-ctn">
                  <p className="font-style-regular">
                    Formu doldurarak başvurunu gerçekleştirebilirsin.
                  </p>
                  <div className="subtitle font-style-regular">
                    Başvuru Tarihleri
                  </div>
                  <div className="date font-style-semi-bold">
                  3 Nisan - 1 Mayıs
                  </div>
                  <div className="button-ctn">
                     <div className="cta">
                      <a
                        href="#"
                        target="_blank"
                        rel="noreferrer"
                        className="button font-style-semi-bold isDisabled"
                      >
                        Hemen Başvur
                      </a>
                    </div> 



                    <div className="row">
                      <div className="col-md-12 col-lg-6 ">
                      <div className="cta linkedin">
                      <a
                        href="#"
                        target="_blank"
                        rel="noreferrer"
                        className="button font-style-semi-bold isDisabled"
                      >
                        <img src={LINKEDIN} alt="linkedin" /> iOS'a
                        Başvur
                      </a>
                    </div>

                    </div>
                      <div className="col-md-12 col-lg-6">
                      <div className="cta linkedin">
                      <a
                        href="#"
                        target="_blank"
                        rel="noreferrer"
                        className="button font-style-semi-bold isDisabled"
                      >
                        <img src={LINKEDIN} alt="linkedin" /> Android'e
                        Başvur
                      </a>
                    </div>
                    </div>

                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default IndexPage
